var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"350px"},model:{value:(_vm.showRefundDialog),callback:function ($$v) {_vm.showRefundDialog=$$v},expression:"showRefundDialog"}},[(_vm.payment)?_c('v-card',[_c('v-card-title',[_vm._v("Refund client")]),_c('v-card-text',[_vm._v(" This action will initiate a refund for client "+_vm._s(_vm.payment.client.fullName)+". Amount: "+_vm._s(((_vm.payment.currency) + " " + (_vm.payment.amount)))+" ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"secondary","rounded":""},on:{"click":function($event){_vm.showRefundDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":_vm.refund}},[_vm._v("Continue")])],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"filters"},[_c('v-btn',{attrs:{"rounded":"","icon":""}},[_c('v-icon',[_vm._v("mdi-filter-variant")])],1),_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"filter-field",attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v("Method: "+_vm._s(_vm.selectedMethod))]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',_vm._l(([
                'All',
                'Card',
                'M-pesa',
                'Voucher',
                'Membership' ]),function(item){return _c('v-list-item',{key:item,on:{"click":function($event){return _vm.fetchPayments(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1),(false)?_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"filter-field",attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('span',[_vm._v(" Service: "),(_vm.selectedService)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedService.name)+" ")]):_c('span',[_vm._v("All")])]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,539070906)},[_c('v-list',[_c('v-list-item',[_vm._v("All")]),_vm._l((_vm.servicePage.docs),function(item,i){return _c('v-list-item',{key:i,on:{"click":function($event){_vm.selectedService = item}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)})],2)],1):_vm._e(),_c('div',{staticClass:"mx-2"}),_c('v-menu',{staticStyle:{"margin-left":"10px !important"},attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[(_vm.selectedStatus)?_c('span',[_vm._v(" "+_vm._s(_vm.selectedStatus)+" ")]):_c('span',[_c('v-icon',[_vm._v("mdi-filter-variant")]),_vm._v("All Payments ")],1),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){_vm.selectedStatus = 'All Payments'}}},[_vm._v(" All Payments ")])],1),_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){_vm.selectedStatus = 'Pending'}}},[_vm._v(" Pending ")])],1),_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){_vm.selectedStatus = 'Completed'}}},[_vm._v(" Completed ")])],1),_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){_vm.selectedStatus = 'Cancelled'}}},[_vm._v(" Cancelled ")])],1),_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){_vm.selectedStatus = 'Refunded'}}},[_vm._v(" Refunded ")])],1)],1)],1),_c('div',{staticClass:"mx-2"}),_c('v-autocomplete',{staticClass:"search-field",attrs:{"items":_vm.paymentPage.docs,"item-text":"client.fullName","item-value":"_id","height":"20","label":"Search","placeholder":"Search by client"},on:{"change":_vm.viewPayment},model:{value:(_vm.payment),callback:function ($$v) {_vm.payment=$$v},expression:"payment"}})],1),_c('div',{staticClass:"extra-btns"},[_c('v-btn',{attrs:{"elevation":"0","small":"","disabled":_vm.exporting},on:{"click":_vm._export}},[_c('v-icon',[_vm._v("mdi-export-variant")]),_vm._v(" Export ")],1)],1)])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.paymentPage.docs,"options":_vm.options,"server-items-length":_vm.paymentPage.total,"no-data-text":"No payments to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"flex-start","align-items":"center"}},[(item.client)?_c('v-avatar',[_c('img',{attrs:{"src":require("../../assets/avatar.png"),"alt":item.client.fullName}})]):_vm._e(),_vm._v("   "),(item.client)?_c('span',{staticStyle:{"white-space":"initial"}},[_vm._v(" "+_vm._s(item.client.fullName)+" ")]):_vm._e()],1)]}},{key:"item.date",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableDate")(item.createdAt,"DD MMM YYYY | h:mm A"))+" ")]}},{key:"item.paymentMethod",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.paymentMethod)+" ")]}},{key:"item.amount",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.currency) + " " + (item.amount))))])]}},{key:"item.paymentStatus",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.order ? item.order.paymentStatus : "")+" "),(item.refundId)?_c('small',[_c('i',[_vm._v("Refund initiated")])]):_vm._e()]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":function($event){_vm.payment = item;
          _vm.showPaymentDetails = true;}}},[_vm._v(" View ")]),(item.source != 'paystack')?_c('v-btn',{attrs:{"disabled":item.paymentStatus == 'pending',"color":"primary","rounded":""},on:{"click":function($event){_vm.payment = item;
          _vm.showRefundDialog = true;}}},[_vm._v(" Refund ")]):_vm._e()]}}],null,true)}),(_vm.showPaymentDetails && _vm.payment)?_c('payment-details',{attrs:{"payment":_vm.payment},on:{"hide-details":function($event){_vm.showPaymentDetails = false;
      _vm.payment = undefined;}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }